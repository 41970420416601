<template>
    <div class="indexFloor">
        <template v-for="(floor, index) in floorList">
            <div :key="index" v-if="floor.type === 1" :ref="'#floor' + floor.id" :id="'#floor' + floor.id" class="floor_one">
                <div class="header">
                    <div class="title">{{JSON.parse(floor.textArea).header.title}}</div>

                    <div class="menu">
                        <span class="text" v-for="(menu, i) in JSON.parse(floor.textArea).header.menu" :key="i + 'menu'" @click="goLink(menu.type, menu.url)">{{menu.name}}</span>
                    </div>
                </div>

                <div class="content">
                    <div class="banner">
                        <el-image :src="JSON.parse(floor.textArea).banner.img" fit="contain" @click="goLink(JSON.parse(floor.textArea).banner.type, JSON.parse(floor.textArea).banner.url)">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>

                    <div class="goods">
                        <a href="javascript:void(0)" v-for="(good, i) in JSON.parse(floor.textArea).goodsList" :key="i + 'good'" @click="goLink(good.type, good.url)" class="item" :class="{large: good.isPrice}">
                            <div class="info" v-if="good.isPrice">
                                <span class="name">{{good.name}}</span>
                                <span class="desc">{{good.desc}}</span>
                                <span class="price">¥{{good.price}}</span>
                            </div>
                            <template v-else>
                                <span class="name">{{good.name}}</span>
                                <span class="desc">{{good.desc}}</span>
                            </template>
                            <div class="image">
                                <el-image :src="good.img" fit="contain">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div :key="index" v-if="floor.type === 2" :ref="'#floor' + floor.id" :id="'#floor' + floor.id" class="floor_one else">
                <div class="header">
                    <div class="title">{{JSON.parse(floor.textArea).header.title}}</div>

                    <div class="menu">
                        <span class="text" v-for="(menu, i) in JSON.parse(floor.textArea).header.menu" :key="i + 'menu'" @click="goLink(menu.type, menu.url)">{{menu.name}}</span>
                    </div>
                </div>

                <div class="content">
                    <div class="banner">
                        <el-image :src="JSON.parse(floor.textArea).banner.img" fit="contain" @click="goLink(JSON.parse(floor.textArea).banner.type, JSON.parse(floor.textArea).banner.url)">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </div>

                    <div class="goods">
                        <a href="javascript:void(0)" v-for="(good, i) in JSON.parse(floor.textArea).goodsList" :key="i + 'good'" @click="goLink(good.type, good.url)" class="item" :class="{large: good.isPrice}">
                            <div class="info" v-if="good.isPrice">
                                <span class="name">{{good.name}}</span>
                                <span class="desc">{{good.desc}}</span>
                                <span class="price">¥{{good.price}}</span>
                            </div>
                            <template v-else>
                                <span class="name">{{good.name}}</span>
                                <span class="desc">{{good.desc}}</span>
                            </template>
                            <div class="image">
                                <el-image :src="good.img" fit="contain">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div :key="index" v-if="floor.type === 3" :ref="'#floor' + floor.id" :id="'#floor' + floor.id" class="floor_two">
                <div class="header">
                    <el-image :src="JSON.parse(floor.textArea).header.img" fit="contain">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <span class="name">{{JSON.parse(floor.textArea).header.title}}</span>
                </div>

                <div class="list">
                    <a href="javascript:void(0)" class="item" v-for="(good, i) in JSON.parse(floor.textArea).goodsList" :key="i + 'good'" @click="goLink(good.type, good.url)" :class="{large: good.isPrice}">
                        <span class="name">{{good.name}}</span>
                        <span class="desc">{{good.desc}}</span>
                        <div class="info">
                            <span class="price">¥{{good.price}}</span>
                            <div class="image">
                                <el-image :src="good.img" fit="contain">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div :key="index" v-if="floor.type === 4" :ref="'#floor' + floor.id" :id="'#floor' + floor.id" class="floor_three">
                <div class="item" v-for="(banner, i) in JSON.parse(floor.textArea)" :key="'banner' + i" @click="goLink(banner.type, banner.url)">
                    <el-image :src="banner.img" fit="contain">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
            </div>

            <div :key="index" v-if="floor.type === 5" class="floor_four" :ref="'#floor' + floor.id" :id="'#floor' + floor.id">
                <div class="header">
                    <div class="title">{{JSON.parse(floor.textArea).header.title}}</div>
                </div>

                <div class="content">
                    <div class="item" v-for="(good, i) in JSON.parse(floor.textArea).goodsList" :key="i + 'good'" @click="goLink(good.type, good.url)">
                        <div class="image">
                            <el-image :src="good.img" fit="contain">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>

                        <div class="info">
                            <span class="name">{{good.name}}</span>
                            <span class="text">{{good.desc}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
  export default {
    name: "floor",
    props: {
      floorList: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {}
    },
    methods: {
      goLink (type, url) {
        if (type === '1') {
          window.open(url)
        }

        if (type === '2') {
          this.$router.push({path: '/shop/detail', query: {id: url}})
        }

        if (type === '3') {
          const { href } = this.$router.resolve({path: '/shop/list', query: {type: '2', id: url, page: '1'}});
          window.location.href = href;
        }

        if (type === '4') {
          this.$router.push({path: '/shop/store', query: {id: url}})
        }
      }
    },
    components: {}
  }
</script>
